import { load } from "@cashfreepayments/cashfree-js";

function Cashfree({ sessionId }) {
  let cashfree;
  let payment_mode = process.env.REACT_APP_CF_PAYMENT_ENV;
  var initializeSDK = async function () {
    cashfree = await load({
      mode: payment_mode,
    });
  };
  initializeSDK();

  const doPayment = async () => {
    let checkoutOptions = {
      paymentSessionId: sessionId,
      redirectTarget: "_self",
    };
    cashfree.checkout(checkoutOptions);
  };

  return (
    <div className="row">
      <p>Click below to open the checkout page in current tab</p>
      <button
        type="submit"
        className="btn btn-primary"
        id="renderBtn"
        onClick={doPayment}
      >
        Pay Now
      </button>
    </div>
  );
}
export default Cashfree;
